import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import NavBar from "../../components/NavBar";
import SelectAutocomplete from "../../components/SelectAutocomplete";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getStudentAsync } from "../../features/students/studentsSlice";
import { generateLetter } from "../../features/pdfsGenerator/pdfsGeneratorSlice";
import { useDispatch } from "react-redux";
import BasicSelect from "../../components/BasicSelect";
import { dataTypeStatistics } from "../../helpers/catalogs";
import BasicButton from "../../components/BasicButton";
import BasicTextFields from "../../components/BasicTextFields";
import InputAdornment from "@mui/material/InputAdornment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import BasicAlert from "../../components/BasicAlert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Back from "../../components/Back";
import { useLocation } from "react-router-dom";
import Loader from "../../components/Loader";
import Scholarship from "../../screens/AdminStatistics/typeStatiscs/scholarship";
import Comunity from "../../screens/AdminStatistics/typeStatiscs/comunity";
import StudentsInformationGraphic from "../../screens/AdminStatistics/typeStatiscs/studentsInformationGraphic";


function AdminStatistics() {
  let location = useLocation();
  const path = location.pathname;
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.students.isLoading);
  const studentsFilter = useSelector((state) => state.students.filter);
  const options = studentsFilter.map((student) => ({
    label: `${student.name}${
      student.secondName ? " " + student.secondName : ""
    }${student.lastName1 ? " " + student.lastName1 : ""}${
      student.lastName2 ? " " + student.lastName2 : ""
    }`,
    value: student.idExternalUser,
  }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getStudentAsync());
      } catch (error) {
        console.error("Error al realizar operaciones asíncronas:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  const [payload, setPayload] = useState({});
  const handleChangeSelectAutocomplete = async (value) => {
    if (value !== null) {
      setPayload({
        ...payload,
        idExternalUser: value.value,
      });
    }
  };

  const handleChange = async (value) => {
    if (value !== null) {
      setPayload({
        ...payload,
        [value.target.name]: value.target.value,
      });
    }
  };

  const handleGenerate = async () => {
    await dispatch(
      generateLetter({
        idExternalUser: payload.idExternalUser,
        type: payload.type,
        semesterStartDate: payload.semesterStartDate,
      })
    )
      .then((response) => {
        if (response?.payload?.status) {
          setDisplayBasicAlert(false);

          window.open(response.payload.url, "_blank");
        } else {
          setDisplayBasicAlert(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setDisplayBasicAlert(true);
      });
  };

  const [displayBasicAlert, setDisplayBasicAlert] = React.useState(false);

  return (
    <Container>
      <NavBar />
      <Loader loading={isLoading} />
      <Box sx={{ mt: -4 }}>
        <Back ruta={"/" + path.split("/")[1]} />
      </Box>
      <Box>
        <Box sx={{ mb: 3 }}>
          <Typography
            sx={{
              mb: 4,
              fontWeight: "bold",
              fontSize: { xs: 28, md: 36 },
              color: "#264496",
              fontFamily: "Nunito, sans-serif",
            }}
          >
            Estadísticas IMC
          </Typography>
          <Box sx={{ maxWidth: "500px", margin: "auto" }}>
            <Box sx={{ mr: 1 }}>
              <BasicSelect
                value={payload.type}
                onChange={handleChange}
                name="type"
                data={dataTypeStatistics}
                label="Tipo de estadística"
              ></BasicSelect>
            </Box>
          </Box>
        </Box>
      </Box>
      <BasicAlert
        display={displayBasicAlert}
        severity="error"
        label="Error al generar pdf"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setDisplayBasicAlert(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      />
      {payload?.type === 1 ? <Scholarship /> : ""}
      {payload?.type === 2 ? <Comunity /> : ""}
      {payload?.type === 3 ? <StudentsInformationGraphic /> : ""}
    </Container>
  );
}

export default AdminStatistics;
