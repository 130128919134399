import * as React from 'react';
import { Chart } from "react-google-charts";

export default function BasicPie({ data,title, width,height}) {

  const options = {
    title,
    titleTextStyle: {
      fontSize: 24, // Cambia este valor para ajustar el tamaño de la letra del título
      bold: true,  // Puedes agregar opciones adicionales como negrita o el color
    },
  };
  return (
    <Chart
      chartType="PieChart"
      data={data??[]}
      options={options}
      width={width??"100%"}
      height={height??"350px"}
    />
  );
}


