import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import {
  getAllActiveStudentsListWithPhotoBySemester,
} from "../../../features/statistics/statisticsSlice";
import { useDispatch } from "react-redux";
import BasicSelect from "../../../components/BasicSelect";
import { dataMonths } from "../../../helpers/catalogs";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Loader from "../../../components/Loader";

function StudentsInformationGraphic() {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.statistics.isLoading);
  const getStatisticsScholarshipData = useSelector((state) => state.statistics.data);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getAllActiveStudentsListWithPhotoBySemester());
      } catch (error) {
        console.error("Error al realizar operaciones asíncronas:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  return (
    <Container>
      <Loader loading={isLoading} />
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          {getStatisticsScholarshipData ?getStatisticsScholarshipData.map((birthday, index) => (
            <Grid item xs={12} sm={6} md={2} key={index}>
              <Card sx={{ maxWidth: 300, minHeight: 280 }}>
                <CardHeader
                  title={
                    <Typography sx={{ fontSize: "13px" }}>
                      {birthday.name} {birthday.secondName} {birthday.lastName1}{" "}
                      {birthday.lastName2}
                    </Typography>
                  }
                />
                <CardMedia
                  component="img"
                  style={{ objectFit: "contain" }}
                  height="150"
                  image={`${birthday.pathPhoto}`}
                  alt="Foto"
                />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    
                    {birthday.semester} semestre
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )):""}
        </Grid>
      </Box>
    </Container>
  );
}

export default StudentsInformationGraphic;
