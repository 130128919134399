import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import BasicPie from "../../../components/BasicPie";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { getStatisticsScholarship } from "../../../features/statistics/statisticsSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader";


export default function ScholarshipDashboard() {
    const dispatch = useDispatch();
    const getStatisticsScholarshipData = useSelector((state) => state.statistics.data);
  const isLoading = useSelector((state) => state.statistics.isLoading);

    useEffect(() => {
      const fetchData = async () => {
        try {
          await dispatch(getStatisticsScholarship());
        } catch (error) {
          console.error("Error al realizar operaciones asíncronas:", error);
        }
      };
  
      fetchData();
    }, [dispatch]);

  const studentsWithScholarshipData = [
    ["Becas", "Porcentaje de Becas"],
    ["Con Beca", getStatisticsScholarshipData?.studentsScholarships?.withScholarship],
    ["Sin Beca", getStatisticsScholarshipData?.studentsScholarships?.withoutScholarship],
    
  ];

  const typesOfScholarshipData = [
    ["Becas", "Tipos de Becas"],
  ];

  if (getStatisticsScholarshipData && getStatisticsScholarshipData.typeScholarship) {
    Object.entries(getStatisticsScholarshipData.typeScholarship).forEach(([scholarshipType, value]) => {
        const formattedScholarshipType = scholarshipType.charAt(0).toUpperCase() + scholarshipType.slice(1);
        typesOfScholarshipData.push([formattedScholarshipType, value]);
    });
}

  const marinaOrMilitaryScholarship = [
    ["Becas", "Beca Marina o Sedena"],
    ["SEDENA", getStatisticsScholarshipData?.militaryScholarships?.sedena ?? 0],
    ["Marina", getStatisticsScholarshipData?.militaryScholarships?.marina ?? 0],
    ["Sin Beca", getStatisticsScholarshipData?.militaryScholarships?.withoutScholarship ?? 0],
  ];

  const earnings = [
    ["Becas", "Ingresos por becas"],
    ["Con beca", getStatisticsScholarshipData?.income?.amountScholarship ?? 0],
    ["Sin becas",getStatisticsScholarshipData?.income?.amountWOScholarship ?? 0],
  ];

  return (
    <Box sx={{  borderRadius: 2 }}>
      <Loader loading={isLoading} />
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              p: 2,
              boxShadow: 3,
              borderRadius: 2,
              backgroundColor: "#fff",
            }}
          >
            <BasicPie
              data={studentsWithScholarshipData}
              width={"100%"}
              height={350}
              title={"Procentaje de becas"}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              p: 2,
              boxShadow: 3,
              borderRadius: 2,
              backgroundColor: "#fff",
            }}
          >
            <BasicPie
              data={typesOfScholarshipData}
              width={"100%"}
              height={350}
              title="Tipos de becas"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              p: 2,
              boxShadow: 3,
              borderRadius: 2,
              backgroundColor: "#fff",
            }}
          >
            <BasicPie
              data={marinaOrMilitaryScholarship}
              width={"100%"}
              height={350}
              title="Becas Marina/SEDENA"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              p: 2,
              boxShadow: 3,
              borderRadius: 2,
              backgroundColor: "#fff",
            }}
          >

            <BasicPie
              data={earnings}
              width={"100%"}
              height={350}
              title="Ingresos"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
