import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  data: [
    {
      name: "",
      secondName: "",
      lastName1: "",
      lastName2: "",
      birthDate: "",
      pathPhoto: "",
      semester:"",
      studentsScholarships:null,
      typeScholarship:null,
      militaryScholarships:null,
      income:null,
      community:null,
      studentsBySemester:null
    },
  ],
  month: new Date().getMonth() + 1,
  isLoading: false,
  error: [
    {
      message: "",
      code: "",
    },
  ],
};

export const getStatisticsScholarship = createAsyncThunk(
  "statistics/getStatisticsScholarship",
  async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: "Bearer " + token };
      const response = await axios.get(
        process.env.REACT_APP_API_CORE_IMC + "/scholarshipService/getStatistics",
        { headers: headers }
      );
      return response.data.data;
    } catch (error) {
      if (error.message.includes(401)) {
        window.location.href = "/401";
      }
      throw error;
    }
  }
);

export const getStatisticsComunity = createAsyncThunk(
  "statistics/getStatisticsComunity",
  async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: "Bearer " + token };
      const response = await axios.get(
        process.env.REACT_APP_API_CORE_IMC + "/generalDataUsers/getStatistics",
        { headers: headers }
      );
      return response.data.data;
    } catch (error) {
      if (error.message.includes(401)) {
        window.location.href = "/401";
      }
      throw error;
    }
  }
);

export const getAllActiveStudentsListWithPhotoBySemester = createAsyncThunk(
  "statistics/getAllActiveStudentsListWithPhotoBySemester",
  async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: "Bearer " + token };
      const response = await axios.get(
        process.env.REACT_APP_API_CORE_IMC + "/generalDataUsers/getAllActiveStudentsListWithPhotoBySemester",
        { headers: headers }
      );
      return response.data.data;
    } catch (error) {
      if (error.message.includes(401)) {
        window.location.href = "/401";
      }
      throw error;
    }
  }
);

export const statisticsSlice = createSlice({
  name: "statistics",
  initialState: initialState,
  reducers: {
    updateMonth: (state, action) => {
      state.month = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStatisticsScholarship.pending, (state) => {
        state.error = [];
        state.isLoading = true;
      })
      .addCase(getStatisticsScholarship.fulfilled, (state, action) => {
        state.data = action.payload;
        state.error = [];
        state.isLoading = false;
      })
      .addCase(getStatisticsScholarship.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getStatisticsComunity.pending, (state) => {
        state.error = [];
        state.isLoading = true;
      })
      .addCase(getStatisticsComunity.fulfilled, (state, action) => {
        state.data = action.payload;
        state.error = [];
        state.isLoading = false;
      })
      .addCase(getStatisticsComunity.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getAllActiveStudentsListWithPhotoBySemester.pending, (state) => {
        state.error = [];
        state.isLoading = true;
      })
      .addCase(getAllActiveStudentsListWithPhotoBySemester.fulfilled, (state, action) => {
        state.data = action.payload;
        state.error = [];
        state.isLoading = false;
      })
      .addCase(getAllActiveStudentsListWithPhotoBySemester.rejected, (state, action) => {
        state.isLoading = false;
      })
      ;
  },
});


export default statisticsSlice.reducer;
