import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import BasicPie from "../../../components/BasicPie";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { getStatisticsComunity } from "../../../features/statistics/statisticsSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader";

export default function Comunity() {

      const dispatch = useDispatch();
      const getStatisticsComunityData = useSelector((state) => state.statistics.data);
    const isLoading = useSelector((state) => state.statistics.isLoading);
  
      useEffect(() => {
        const fetchData = async () => {
          try {
            await dispatch(getStatisticsComunity());
          } catch (error) {
            console.error("Error al realizar operaciones asíncronas:", error);
          }
        };
    
        fetchData();
      }, [dispatch]);

  const membersComunityData = [
    ["Comunidad", "Integrantes"],
    ["Alumnos",  getStatisticsComunityData?.community?.students],
    ["Planta", getStatisticsComunityData?.community?.staff],
    ["Docentes",  getStatisticsComunityData?.community?.teachers],
  ];

  const studentsSemesterData = [
    ["Comunidad", "Alumnos por semestre"]
  ];


// Crear un mapa para traducir los números de semestre a su nombre correspondiente
const semesterNames = {
  "2": "Segundo",
  "4": "Cuarto",
  "6": "Sexto"
};

// Recorrer dinámicamente los datos de alumnos por semestre y añadirlos a la estructura
if (getStatisticsComunityData && getStatisticsComunityData.studentsBySemester) {
Object.entries(getStatisticsComunityData.studentsBySemester).forEach(([semester, count]) => {
  const semesterName = semesterNames[semester] || semester;  // Usamos el nombre traducido o el mismo semestre
  studentsSemesterData.push([semesterName, count]);
});
}

  return (
    <Box sx={{  borderRadius: 2 }}>
            <Loader loading={isLoading} />
      
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              p: 2,
              boxShadow: 3,
              borderRadius: 2,
              backgroundColor: "#fff",
            }}
          >
            <BasicPie
              data={membersComunityData}
              width={"100%"}
              height={350}
              title={"Comunidad IMC"}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              p: 2,
              boxShadow: 3,
              borderRadius: 2,
              backgroundColor: "#fff",
            }}
          >
            <BasicPie
              data={studentsSemesterData}
              width={"100%"}
              height={350}
              title="Alumnos por semestre"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
